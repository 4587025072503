export const environment = {
  production: false,
  // apiBaseUrl: 'https://app.grintafy.com/', // old
  // apiBaseUrl: 'https://livenode.grintafy.com/', // old

   //apiBaseUrl: 'https://dev-app.grintafy.com/', //  Test Server
  //  apiBaseUrl: 'https://api-palade7.grintafy.com/', //  Test Server

   apiBaseUrl: 'https://prodapi-palade7.grintafy.com/', // Live Serverss 

  //apiBaseUrl: 'http://54.70.18.204:8000/' --old
};



