import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './external/login/login.component';
import {TermsComponent} from './external/terms/terms.component';
import {PrivacyComponent} from './external/privacy/privacy.component';
import {ExternalAuthguardService} from './services/externalAuthguard.service';
import {ReferralComponent} from './external/referral-code/referral.component'; 
import { ForgotComponent } from './external/forgot/forgot.component';

const routes: Routes = [
    {path: '', redirectTo: '/login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent, canActivate: [ExternalAuthguardService]},
    {path: 'forgot', component: ForgotComponent, canActivate: [ExternalAuthguardService]},
    {path: 'terms', component: TermsComponent},
    {path: 'privacy', component: PrivacyComponent},
    {path: 'referral', component: ReferralComponent},
    {path: '', loadChildren: () => import('./internal/internal.module').then(m => m.InternalModule)},
    {path: '**', component: LoginComponent, canActivate: [ExternalAuthguardService]}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})


export class AppRoutingModule {
}
