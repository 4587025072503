import {ApplicationRef, Component, NgZone, OnInit} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {PlatformLocation} from '@angular/common';
import {Location} from '@angular/common';
import {ApiService} from './services/api.service';
import {VersionCheckService} from './services/versionCheck.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    message;

    // showModal = false;
    constructor(
        private api: ApiService, private swUpdate: SwUpdate,
        private zone: NgZone, location: PlatformLocation, public router: Router,
        private _location: Location, private applicationRef: ApplicationRef,
        private versionCheckService: VersionCheckService,
    ) {
        router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {
                this.zone.run(() => {
                    setTimeout(() => {
                        applicationRef.tick();
                    }, 500);
                });
            }
            // this.navigationInterceptor(event);
        });

        // tariq sir
        // location.onPopState(() => {
        //     this.zone.run(() => {
        //         setTimeout(() => {
        //             applicationRef.tick();
        //         }, 500);
        //     });
        // });


        // location.onPopState(() => {
        //     this.zone.run(() => {
        //         const url = this.getUrl();
        //         this.api.navigate(url, '');
        //         // this.router.navigate([url]);
        //     });
        // });

    }

    ngOnInit() {

        //this.versionCheckService.initVersionCheck(environment.versionCheckURL);

        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                /* if (confirm('New version available. Load New Version?')) {
                    window.location.reload();
                } */
            });
       }
       //this.swUpdate.checkForUpdate
    }

    getUrl() {
        const len = this._location.path().indexOf(';');
        let str = this._location.path();
        if (len !== -1) {
            str = (this._location.path().slice(0, (len)));
        }
        return str;
    }

}
