import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthGuardService as AuthGuard} from './services/authguard.service';
import {AppComponent} from './app.component';
import {LoginComponent} from './external/login/login.component';
import {CapitalizePipe} from './shared/pipes/capitalizefirst.pipe';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {LoadingModule} from 'ngx-loading';
import {ApiService} from './services/api.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {ToastrModule} from 'ngx-toastr';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {InterceptorService} from './services/interceptor.service';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {LightboxModule} from 'ngx-lightbox';
import {AsyncPipe} from '@angular/common';
import {ClickOutsideModule} from 'ng-click-outside';
import {TermsComponent} from './external/terms/terms.component';
import {PrivacyComponent} from './external/privacy/privacy.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {ExternalAuthguardService} from './services/externalAuthguard.service';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {ReferralComponent} from './external/referral-code/referral.component';
import { ForgotComponent } from './external/forgot/forgot.component';
import {VersionCheckService} from './services/versionCheck.service';

@NgModule({
    declarations: [
        CapitalizePipe,
        AppComponent,
        LoginComponent,
        TermsComponent,
        PrivacyComponent,
        ReferralComponent,
        ForgotComponent
    ],
    imports: [
        PaginationModule.forRoot(),
        GooglePlaceModule,
        Ng2TelInputModule,
        BrowserModule,
        ClickOutsideModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        // NgxIntlTelInputModule,  
        ReactiveFormsModule,
        LoadingModule.forRoot({
            primaryColour: '#F56600',
            tertiaryColour: '#F56600'
        }),
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: environment.production
        }),

        CommonModule,
        BrowserAnimationsModule,
        AngularFontAwesomeModule,
        ToastrModule.forRoot({
            closeButton: true,
            timeOut: 3000,
            progressAnimation: 'increasing',
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true,
            progressBar: true
        }),
        LazyLoadImageModule,
        LightboxModule,
        NgMultiSelectDropDownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot()

    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
        ApiService,
        AuthGuard,
        AsyncPipe,
        ExternalAuthguardService,
        VersionCheckService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
