import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import * as constant from './constants';
import {apiUrl} from './apiUrls';
import {ToastrService} from 'ngx-toastr';
import {Lightbox} from 'ngx-lightbox';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Location} from '@angular/common';

@Injectable()
export class ApiService {

    constant = constant;
    apiUrl = apiUrl;
    noImage = '/assets/images/noImage.png';
    userPlaceholder = '/assets/images/ic_user.png';
    placeholder = '/assets/images/placeholder.jpg';
    gif = '/assets/images/gif.gif';
    pdf = '/assets/images/pdf.jpeg';
    modalRef: BsModalRef;

    constructor(
        private router: Router,
        private http: HttpClient,
        public toastr: ToastrService,
        public lightBox: Lightbox,
        public modalService: BsModalService,
        private location: Location
    ) {
        this.apiEndpoint = environment.apiBaseUrl;
    }

    public readonly apiEndpoint: String;
    private subject = new BehaviorSubject<any>(null);
    public title = this.subject.asObservable();

    private loaderSubject = new BehaviorSubject<any>(null);
    public loaderStatus = this.loaderSubject.asObservable();

    setTouched(form) {
        Object.keys(form.controls).forEach(key => {
            form.controls[key].markAsTouched({onlySelf: true});
        });
    }

    getSelectedValues(arr, id) {
        let temp: any = [];
        arr.forEach((val) => {
            temp.push(val[id]);
        });
        return temp;
    }

    // show selected to dropdown match with backend
    selectedInArray(allvalues, selected) {
        let tempArr: any = [];
        allvalues.forEach((val) => {
            selected.forEach((val1) => {
                if (val._id === val1) {
                    tempArr.push(val);
                }
            });
        });
        return tempArr;
    }

    isFormValid(form) {
        if (form.valid) {
            return true;
        } else {
             const invalid = [];
            Object.keys(form.controls).forEach(key => {
                form.controls[key].markAsTouched({onlySelf: true});
                 if (form.controls[key].invalid) {
                    invalid.push(key);
                }
            });
            console.log('invalid', invalid)
            return false;
        }

    }

    goBackFun() {
        this.location.back();
    }

    gotoUrl(obj) {
        this.router.navigate([], {queryParams: obj});
    }

    getDataFromFile(path) {
        return this.http.get(path);
    }

    navigate(url, params?) {
        if (params) {
            this.router.navigate([`/${url}`, params]);
        } else {
            this.router.navigate([`/${url}`]);
        }
    }

    changeTitle(title) {
        this.subject.next(title);
    }

    loaderOn(loaderStatus) {
        this.loaderSubject.next(loaderStatus);
    }

    removeEmptyKeys(obj) {
        Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key]);
    }


    urltoFile(url, filename, mimeType) {
        const byteString = atob(url.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new File([ab], filename, {'type': mimeType});
    }

    getData(url, obj, loader?) {
        let params = new HttpParams();
        Object.keys(obj).forEach(key => {
            if (obj[key] !== '' && obj[key] !== undefined) {
                params = params.set(key, obj[key]);
            }
        });

        // Object.keys(obj).forEach(key => {
        //     if (obj[key]) {
        //         params = params.set(key, obj[key]);
        //     }
        // });
        return this.http.get<any>(this.apiEndpoint + url, {params: params, reportProgress: loader});
    }

    getDataNew(url, obj, loader) {
        return this.http.get<any>(this.apiEndpoint + url, {params: obj, reportProgress: loader});
    }
    getWithNoParam(url) {
        return this.http.get<any>(this.apiEndpoint + url);
    }
    postData(url, data) {
        return this.http.post<any>(this.apiEndpoint + url, data, {reportProgress: true});
    }
    patchData(url, data) {
        return this.http.patch<any>(this.apiEndpoint + url, data, {reportProgress: true});
    }
    
    postDataNew(url, obj, isLoading?: boolean) {
        const formData = new FormData();
        Object.keys(obj).forEach(key => {
            if (obj[key] !== '' && obj[key] !== undefined) {
                formData.append(key, obj[key]);
            }
        });
        return this.http.post<any>(this.apiEndpoint + url, formData, {reportProgress: isLoading});
    }

    putData(url, data ,loader=true) {
        return this.http.put<any>(this.apiEndpoint + url, data, {reportProgress: loader});
    }

    openLightBox(data) {        
        if (data) {
            const temp: any = [];
            temp.push({
                src: data,
                thumb: data
            });
            this.lightBox.open(temp, 0);
            return true;
        }
    }

    uploadImage(url, file) {
        if (file && file.type.match('image.*')) {
            const formData = new FormData();
            formData.append('imageToUpload', file);
            return this.http.post<any>(this.apiEndpoint + url, formData);
        }
    }

    uploadVideo(url, formData) {
        return this.http.post<any>(this.apiEndpoint + url, formData, {reportProgress: true});
    }

    checkImage(file) {
        if (file) {
            if (file.size < 5000000) {
                if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                    return true;
                } else {
                    this.toastr.error('Please add jpg or png image only');
                }
            } else {
                this.toastr.error('Please add image less than 5 MB');
            }
        }
        return false;
    }

    showModal(template, size, backdropType?: any) {
        if (size) {
            this.modalRef = this.modalService.show(template,
                {
                    backdrop: !backdropType ? 'static' : true,
                    keyboard: false,
                    class: `gray modal-${size}`
                }
            );
        } else {
            this.modalRef = this.modalService.show(template,
                {
                    backdrop: 'static',
                    keyboard: false
                }
            );
        }
    }
    getAcademicRegData(url, loader) {
        return this.http.get<any>(url, {reportProgress: loader});
    }
    hideModal() {
        this.modalRef.hide();
    }

    blocked() {
        this.toastr.success('Blocked Successfully');
    }

    unblocked() {
        this.toastr.success('Unblocked Successfully');
    }
    locked() {
        this.toastr.success('Locked Successfully');
    }

    playersadded() {
        this.toastr.success('Player Successfully Added');
    }
    playersremoved() {
        this.toastr.success('Player Successfully Removed');
    }

    declined() {
        this.toastr.success('Successfully Declined');
    }
    approved() {
        this.toastr.success('Successfully Approved');
    }


    unlocked() {
        this.toastr.success('Unlocked Successfully');
    }

    favourited() {
        this.toastr.success('Marked As Favourite.');
    }

    unfavourited() {
        this.toastr.success('Remove from Favourite.');
    }

    updated() {
        this.toastr.success('Updated Successfully');
    }

    stop() {
        this.toastr.success('Stop Successfully');
    }

    reactive() {
        this.toastr.success('Reactive Successfully');
    }

    statusPaused() {
        this.toastr.success('Status Paused Successfully');
    }

    added() {
        this.toastr.success('Added Successfully');
    }

    deleted() {
        this.toastr.success('Deleted Successfully');
    }
    rejected() {
        this.toastr.success(' Sucessfully Rejected');
    }

    accepted() {
        this.toastr.success(' Successfully Accepted');
    }
    notFound() {
        this.toastr.error(' No record found!');
    }
    notDataFound() {
        this.toastr.error('There is no matching data!');
    }

    percentCheck() {
        this.toastr.error('In case of percent type Amount can not be greater-than 100!');
    }

    usedBudgetCheck() {
        this.toastr.error('You can`t add budget amount if its less than the current used amount');
    }

    amountCheck() {
        this.toastr.error('Discount can not be 0');
    }

    endDateCheck() {
        this.toastr.error('End Date shoud be greater than Start Date!');
    }
    startDateCheck() {
        this.toastr.error('Start Date shoud be greater than Current Date!');
    }
    cantDeleteActive() {
        this.toastr.error('Sorry you can not delete used promo!');
    }
    cantDeleteActiveV2() {
        this.toastr.error('Sorry you can not delete active survey!');
    }
    startEndDateCheck() {
        this.toastr.error('End Date shoud be greater than current date!');
    }

    promoAdded() {
        this.toastr.success(' Promo Added sucessfully');
    }
    promoUpdated() {
        this.toastr.success(' Promo Updated sucessfully');
    }
    promoSaved() {
        this.toastr.success(' Promo Saved sucessfully');
    }
    promoPublished() {
        this.toastr.success(' Promo Publisted sucessfully');
    }
    adminUpdated() {
        this.toastr.success(' Record added sucessfully');
    }
    maxLimition() { 
        return 'The max price should be greater than or equal to the min price';
    }
    maxLimition2(){
        return 'The min price should be greater than or equal to the discount amount';
    }
    budgetAmountZeeroLimit(){
        return 'The budget amount can not be 0 value it have to be greater than 0 value';
    }
    budgetAmountLimit(){
        return 'The budget amount have to be greater than or equal the discount amount';
    }
    addEditScoutUser(action) {
        if(action ==1){
            this.toastr.success(' The Scout user Successfully added');
        }
        else if(action==0){
            this.toastr.success(' Success! you edit has been saved');
        }
        else if(action==3){
            this.toastr.success(' The user is blocked');
        }
        else if(action==4){
            this.toastr.success(' The user is unblocked');
        }
        
    }

    dtcMessages(action) {

        if(action ==1){
            this.toastr.success(' The filters has been successfully applied');
        }
        else if(action ==2){
            this.toastr.success(' The filters has been reset successfully');
        }
    }

    countryNameList = [
        { countryCode: "+93", countryName: "AF +93", countryFlag: '../assets/svg-country-flags/svg/af.svg' },
        { countryCode: "+358", countryName: "AX +358", countryFlag: '../assets/svg-country-flags/svg/ax.svg' },
        { countryCode: "+355", countryName: "AL +355", countryFlag: '../assets/svg-country-flags/svg/al.svg' },
        { countryCode: "+213", countryName: "DZ +213", countryFlag: '../assets/svg-country-flags/svg/dz.svg' },
        { countryCode: "+1684", countryName: "AS +1684", countryFlag: '../assets/svg-country-flags/svg/as.svg' },
        { countryCode: "+376", countryName: "AD +376", countryFlag: '../assets/svg-country-flags/svg/ad.svg' },
        { countryCode: "+244", countryName: "AO +244", countryFlag: '../assets/svg-country-flags/svg/ao.svg' },
        { countryCode: "+1264", countryName: "AI +1264", countryFlag: '../assets/svg-country-flags/svg/ai.svg' },
        { countryCode: "+672", countryName: "AQ +672", countryFlag: '../assets/svg-country-flags/svg/aq.svg' },
        { countryCode: "+1268", countryName: "AG +1268", countryFlag: '../assets/svg-country-flags/svg/ag.svg' },
        { countryCode: "+54", countryName: "AR +54", countryFlag: '../assets/svg-country-flags/svg/ar.svg' },
        { countryCode: "+374", countryName: "AM +374", countryFlag: '../assets/svg-country-flags/svg/am.svg' },
        { countryCode: "+297", countryName: "AW +297", countryFlag: '../assets/svg-country-flags/svg/aw.svg' },
        { countryCode: "+61", countryName: "AU +61", countryFlag: '../assets/svg-country-flags/svg/au.svg' },
        { countryCode: "+43", countryName: "AT +43", countryFlag: '../assets/svg-country-flags/svg/at.svg' },
        { countryCode: "+994", countryName: "AZ +994", countryFlag: '../assets/svg-country-flags/svg/az.svg' },
        { countryCode: "+1242", countryName: "BS +1242", countryFlag: '../assets/svg-country-flags/svg/bs.svg' },
        { countryCode: "+973", countryName: "BH +973", countryFlag: '../assets/svg-country-flags/svg/bh.svg' },
        { countryCode: "+880", countryName: "BD +880", countryFlag: '../assets/svg-country-flags/svg/bd.svg' },
        { countryCode: "+1246", countryName: "BB +1246", countryFlag: '../assets/svg-country-flags/svg/bb.svg' },
        { countryCode: "+375", countryName: "BY +375", countryFlag: '../assets/svg-country-flags/svg/by.svg' },
        { countryCode: "+32", countryName: "BE +32", countryFlag: '../assets/svg-country-flags/svg/be.svg' },
        { countryCode: "+501", countryName: "BZ +501", countryFlag: '../assets/svg-country-flags/svg/bz.svg' },
        { countryCode: "+229", countryName: "BJ +229", countryFlag: '../assets/svg-country-flags/svg/bj.svg' },
        { countryCode: "+1441", countryName: "BM +1441", countryFlag: '../assets/svg-country-flags/svg/bm.svg' },
        { countryCode: "+975", countryName: "BT +975", countryFlag: '../assets/svg-country-flags/svg/bt.svg' },
        { countryCode: "+591", countryName: "BO +591", countryFlag: '../assets/svg-country-flags/svg/bo.svg' },
        { countryCode: "+387", countryName: "BA +387", countryFlag: '../assets/svg-country-flags/svg/ba.svg' },
        { countryCode: "+267", countryName: "BW +267", countryFlag: '../assets/svg-country-flags/svg/bw.svg' },
        { countryCode: "+47", countryName: "BV +47", countryFlag: '../assets/svg-country-flags/svg/bv.svg' },
        { countryCode: "+55", countryName: "BR +55", countryFlag: '../assets/svg-country-flags/svg/br.svg' },
        { countryCode: "+673", countryName: "BN +673", countryFlag: '../assets/svg-country-flags/svg/bn.svg' },
        { countryCode: "+359", countryName: "BG +359", countryFlag: '../assets/svg-country-flags/svg/bg.svg' },
        { countryCode: "+226", countryName: "BF +226", countryFlag: '../assets/svg-country-flags/svg/bf.svg' },
        { countryCode: "+257", countryName: "BI +257", countryFlag: '../assets/svg-country-flags/svg/bi.svg' },
        { countryCode: "+855", countryName: "KH +855", countryFlag: '../assets/svg-country-flags/svg/kh.svg' },
        { countryCode: "+237", countryName: "🇨🇲 +237", countryFlag: '../assets/svg-country-flags/svg/cm.svg' },
        { countryCode: "+1", countryName: "CA +1", countryFlag: '../assets/svg-country-flags/svg/ca.svg' },
        { countryCode: "+238", countryName: "CV +238", countryFlag: '../assets/svg-country-flags/svg/cv.svg' },
        { countryCode: "+345", countryName: "KY +345", countryFlag: '../assets/svg-country-flags/svg/ky.svg' },
        { countryCode: "+236", countryName: "CF +236", countryFlag: '../assets/svg-country-flags/svg/cf.svg' },
        { countryCode: "+235", countryName: "TD +235", countryFlag: '../assets/svg-country-flags/svg/td.svg' },
        { countryCode: "+56", countryName: "CL +56", countryFlag: '../assets/svg-country-flags/svg/cl.svg' },
        { countryCode: "+86", countryName: "CN +86", countryFlag: '../assets/svg-country-flags/svg/cn.svg' },
        { countryCode: "+61", countryName: "CX +61", countryFlag: '../assets/svg-country-flags/svg/cx.svg' },
        { countryCode: "+61", countryName: "CC +61", countryFlag: '../assets/svg-country-flags/svg/cc.svg' },
        { countryCode: "+57", countryName: "CO +57", countryFlag: '../assets/svg-country-flags/svg/co.svg' },
        { countryCode: "+269", countryName: "KM +269", countryFlag: '../assets/svg-country-flags/svg/km.svg' },
        { countryCode: "+242", countryName: "CG +242", countryFlag: '../assets/svg-country-flags/svg/cg.svg' },
        { countryCode: "+243", countryName: "CD +243", countryFlag: '../assets/svg-country-flags/svg/cd.svg' },
        { countryCode: "+682", countryName: "CK +682", countryFlag: '../assets/svg-country-flags/svg/ck.svg' },
        { countryCode: "+506", countryName: "CR +506", countryFlag: '../assets/svg-country-flags/svg/cr.svg' },
        { countryCode: "+225", countryName: "CI +225", countryFlag: '../assets/svg-country-flags/svg/ci.svg' },
        { countryCode: "+385", countryName: "HR +385", countryFlag: '../assets/svg-country-flags/svg/hr.svg' },
        { countryCode: "+53", countryName: "CU +53", countryFlag: '../assets/svg-country-flags/svg/cu.svg' },
        { countryCode: "+357", countryName: "CY +357", countryFlag: '../assets/svg-country-flags/svg/cy.svg' },
        { countryCode: "+420", countryName: "CZ +420", countryFlag: '../assets/svg-country-flags/svg/cz.svg' },
        { countryCode: "+45", countryName: "DK +45", countryFlag: '../assets/svg-country-flags/svg/dk.svg' },
        { countryCode: "+253", countryName: "DJ +253", countryFlag: '../assets/svg-country-flags/svg/dj.svg' },
        { countryCode: "+1767", countryName: "DM +1767", countryFlag: '../assets/svg-country-flags/svg/dm.svg' },
        { countryCode: "+1849", countryName: "DO +1849", countryFlag: '../assets/svg-country-flags/svg/do.svg' },
        { countryCode: "+593", countryName: "EC +593", countryFlag: '../assets/svg-country-flags/svg/ec.svg' },
        { countryCode: "+20", countryName: "EG +20", countryFlag: '../assets/svg-country-flags/svg/eg.svg' },
        { countryCode: "+503", countryName: "SV +503", countryFlag: '../assets/svg-country-flags/svg/sv.svg' },
        { countryCode: "+240", countryName: "GQ +240", countryFlag: '../assets/svg-country-flags/svg/gq.svg' },
        { countryCode: "+291", countryName: "ER +291", countryFlag: '../assets/svg-country-flags/svg/er.svg' },
        { countryCode: "+372", countryName: "EE +372", countryFlag: '../assets/svg-country-flags/svg/ee.svg' },
        { countryCode: "+251", countryName: "ET +251", countryFlag: '../assets/svg-country-flags/svg/et.svg' },
        { countryCode: "+500", countryName: "FK +500", countryFlag: '../assets/svg-country-flags/svg/fk.svg' },
        { countryCode: "+298", countryName: "FO +298", countryFlag: '../assets/svg-country-flags/svg/fo.svg' },
        { countryCode: "+679", countryName: "FJ +679", countryFlag: '../assets/svg-country-flags/svg/fj.svg' },
        { countryCode: "+358", countryName: "FI +358", countryFlag: '../assets/svg-country-flags/svg/fi.svg' },
        { countryCode: "+33", countryName: "FR +33", countryFlag: '../assets/svg-country-flags/svg/fr.svg' },
        { countryCode: "+594", countryName: "GF +594", countryFlag: '../assets/svg-country-flags/svg/gf.svg' },
        { countryCode: "+689", countryName: "PF +689", countryFlag: '../assets/svg-country-flags/svg/pf.svg' },
        { countryCode: "+262", countryName: "TF +262", countryFlag: '../assets/svg-country-flags/svg/tf.svg' },
        { countryCode: "+241", countryName: "GA +241", countryFlag: '../assets/svg-country-flags/svg/ga.svg' },
        { countryCode: "+220", countryName: "GM +220", countryFlag: '../assets/svg-country-flags/svg/gm.svg' },
        { countryCode: "+995", countryName: "GE +995", countryFlag: '../assets/svg-country-flags/svg/ge.svg' },
        { countryCode: "+49", countryName: "DE +49", countryFlag: '../assets/svg-country-flags/svg/de.svg' },
        { countryCode: "+233", countryName: "GH +233", countryFlag: '../assets/svg-country-flags/svg/gh.svg' },
        { countryCode: "+350", countryName: "GI +350", countryFlag: '../assets/svg-country-flags/svg/gi.svg' },
        { countryCode: "+30", countryName: "GR +30", countryFlag: '../assets/svg-country-flags/svg/gr.svg' },
        { countryCode: "+299", countryName: "GL +299", countryFlag: '../assets/svg-country-flags/svg/gl.svg' },
        { countryCode: "+1473", countryName: "GD +1473", countryFlag: '../assets/svg-country-flags/svg/gd.svg' },
        { countryCode: "+590", countryName: "GP +590", countryFlag: '../assets/svg-country-flags/svg/gp.svg' },
        { countryCode: "+1671", countryName: "GU +1671", countryFlag: '../assets/svg-country-flags/svg/gu.svg' },
        { countryCode: "+502", countryName: "GT +502", countryFlag: '../assets/svg-country-flags/svg/gt.svg' },
        { countryCode: "+44", countryName: "GG +44", countryFlag: '../assets/svg-country-flags/svg/gg.svg' },
        { countryCode: "+224", countryName: "GN +224", countryFlag: '../assets/svg-country-flags/svg/gn.svg' },
        { countryCode: "+245", countryName: "GW +245", countryFlag: '../assets/svg-country-flags/svg/gw.svg' },
        { countryCode: "+592", countryName: "GY +592", countryFlag: '../assets/svg-country-flags/svg/gy.svg' },
        { countryCode: "+509", countryName: "HT +509", countryFlag: '../assets/svg-country-flags/svg/ht.svg' },
        { countryCode: "+672", countryName: "HM +672", countryFlag: '../assets/svg-country-flags/svg/hm.svg' },
        { countryCode: "+379", countryName: "VA +379", countryFlag: '../assets/svg-country-flags/svg/va.svg' },
        { countryCode: "+504", countryName: "HN +504", countryFlag: '../assets/svg-country-flags/svg/hn.svg' },
        { countryCode: "+852", countryName: "HK +852", countryFlag: '../assets/svg-country-flags/svg/hk.svg' },
        { countryCode: "+36", countryName: "HU +36", countryFlag: '../assets/svg-country-flags/svg/hu.svg' },
        { countryCode: "+354", countryName: "IS +354", countryFlag: '../assets/svg-country-flags/svg/is.svg' },
        { countryCode: "+91", countryName: "IN +91", countryFlag: '../assets/svg-country-flags/svg/in.svg' },
        { countryCode: "+62", countryName: "ID +62", countryFlag: '../assets/svg-country-flags/svg/id.svg' },
        { countryCode: "+98", countryName: "IR +98", countryFlag: '../assets/svg-country-flags/svg/ir.svg' },
        { countryCode: "+964", countryName: "IQ +964", countryFlag: '../assets/svg-country-flags/svg/iq.svg' },
        { countryCode: "+353", countryName: "IE +353", countryFlag: '../assets/svg-country-flags/svg/ie.svg' },
        { countryCode: "+44", countryName: "IM +44", countryFlag: '../assets/svg-country-flags/svg/im.svg' },
        { countryCode: "+246", countryName: "IO +246", countryFlag: '../assets/svg-country-flags/svg/io.svg' },
        // { countryCode: "+972", countryName: "🇮🇱 +972" , countryFlag : '../assets/svg-country-flags/svg/il.svg' },
        { countryCode: "+39", countryName: "IT +39", countryFlag: '../assets/svg-country-flags/svg/it.svg' },
        { countryCode: "+1876", countryName: "JM +1876", countryFlag: '../assets/svg-country-flags/svg/jm.svg' },
        { countryCode: "+81", countryName: "JP +81", countryFlag: '../assets/svg-country-flags/svg/jp.svg' },
        { countryCode: "+44", countryName: "JE +44", countryFlag: '../assets/svg-country-flags/svg/je.svg' },
        { countryCode: "+962", countryName: "JO +962", countryFlag: '../assets/svg-country-flags/svg/jo.svg' },
        { countryCode: "+7", countryName: "KZ +7", countryFlag: '../assets/svg-country-flags/svg/kz.svg' },
        { countryCode: "+254", countryName: "KE +254", countryFlag: '../assets/svg-country-flags/svg/ke.svg' },
        { countryCode: "+686", countryName: "KI +686", countryFlag: '../assets/svg-country-flags/svg/ki.svg' },
        { countryCode: "+850", countryName: "KP +850", countryFlag: '../assets/svg-country-flags/svg/kp.svg' },
        { countryCode: "+82", countryName: "KR +82", countryFlag: '../assets/svg-country-flags/svg/kr.svg' },
        { countryCode: "+383", countryName: "XK +383", countryFlag: '../assets/svg-country-flags/svg/xk.svg' },
        { countryCode: "+965", countryName: "KW +965", countryFlag: '../assets/svg-country-flags/svg/kw.svg' },
        { countryCode: "+996", countryName: "KG +996", countryFlag: '../assets/svg-country-flags/svg/kg.svg' },
        { countryCode: "+856", countryName: "LA +856", countryFlag: '../assets/svg-country-flags/svg/la.svg' },
        { countryCode: "+371", countryName: "LV +371", countryFlag: '../assets/svg-country-flags/svg/lv.svg' },
        { countryCode: "+961", countryName: "LB +961", countryFlag: '../assets/svg-country-flags/svg/lb.svg' },
        { countryCode: "+266", countryName: "LS +266", countryFlag: '../assets/svg-country-flags/svg/ls.svg' },
        { countryCode: "+231", countryName: "LR +231", countryFlag: '../assets/svg-country-flags/svg/lr.svg' },
        { countryCode: "+218", countryName: "LY +218", countryFlag: '../assets/svg-country-flags/svg/ly.svg' },
        { countryCode: "+423", countryName: "LI +423", countryFlag: '../assets/svg-country-flags/svg/li.svg' },
        { countryCode: "+370", countryName: "LT +370", countryFlag: '../assets/svg-country-flags/svg/lt.svg' },
        { countryCode: "+352", countryName: "LU +352", countryFlag: '../assets/svg-country-flags/svg/lu.svg' },
        { countryCode: "+853", countryName: "MO +853", countryFlag: '../assets/svg-country-flags/svg/mo.svg' },
        { countryCode: "+389", countryName: "MK +389", countryFlag: '../assets/svg-country-flags/svg/mk.svg' },
        { countryCode: "+261", countryName: "MG +261", countryFlag: '../assets/svg-country-flags/svg/mg.svg' },
        { countryCode: "+265", countryName: "MW +265", countryFlag: '../assets/svg-country-flags/svg/mw.svg' },
        { countryCode: "+60", countryName: "MY +60", countryFlag: '../assets/svg-country-flags/svg/my.svg' },
        { countryCode: "+960", countryName: "MV +960", countryFlag: '../assets/svg-country-flags/svg/mv.svg' },
        { countryCode: "+223", countryName: "ML +223", countryFlag: '../assets/svg-country-flags/svg/ml.svg' },
        { countryCode: "+356", countryName: "MT +356", countryFlag: '../assets/svg-country-flags/svg/mt.svg' },
        { countryCode: "+692", countryName: "MH +692", countryFlag: '../assets/svg-country-flags/svg/mh.svg' },
        { countryCode: "+596", countryName: "MQ +596", countryFlag: '../assets/svg-country-flags/svg/mq.svg' },
        { countryCode: "+222", countryName: "MR +222", countryFlag: '../assets/svg-country-flags/svg/mr.svg' },
        { countryCode: "+230", countryName: "MU +230", countryFlag: '../assets/svg-country-flags/svg/mu.svg' },
        { countryCode: "+262", countryName: "YT +262", countryFlag: '../assets/svg-country-flags/svg/yt.svg' },
        { countryCode: "+52", countryName: "MX +52", countryFlag: '../assets/svg-country-flags/svg/mx.svg' },
        { countryCode: "+691", countryName: "FM +691", countryFlag: '../assets/svg-country-flags/svg/fm.svg' },
        { countryCode: "+373", countryName: "MD +373", countryFlag: '../assets/svg-country-flags/svg/md.svg' },
        { countryCode: "+377", countryName: "MC +377", countryFlag: '../assets/svg-country-flags/svg/mc.svg' },
        { countryCode: "+976", countryName: "MN +976", countryFlag: '../assets/svg-country-flags/svg/mn.svg' },
        { countryCode: "+382", countryName: "ME +382", countryFlag: '../assets/svg-country-flags/svg/me.svg' },
        { countryCode: "+1664", countryName: "MS +1664", countryFlag: '../assets/svg-country-flags/svg/ms.svg' },
        { countryCode: "+212", countryName: "MA +212", countryFlag: '../assets/svg-country-flags/svg/ma.svg' },
        { countryCode: "+258", countryName: "MZ +258", countryFlag: '../assets/svg-country-flags/svg/mz.svg' },
        { countryCode: "+95", countryName: "MM +95", countryFlag: '../assets/svg-country-flags/svg/mm.svg' },
        { countryCode: "+264", countryName: "NA +264", countryFlag: '../assets/svg-country-flags/svg/na.svg' },
        { countryCode: "+674", countryName: "NR +674", countryFlag: '../assets/svg-country-flags/svg/nr.svg' },
        { countryCode: "+977", countryName: "NP +977", countryFlag: '../assets/svg-country-flags/svg/np.svg' },
        { countryCode: "+31", countryName: "NL +31", countryFlag: '../assets/svg-country-flags/svg/nl.svg' },
        { countryCode: "+599", countryName: "NA +599", countryFlag: '../assets/svg-country-flags/svg/na.svg' },
        { countryCode: "+687", countryName: "NC +687", countryFlag: '../assets/svg-country-flags/svg/nc.svg' },
        { countryCode: "+64", countryName: "NZ +64", countryFlag: '../assets/svg-country-flags/svg/nz.svg' },
        { countryCode: "+505", countryName: "NI +505", countryFlag: '../assets/svg-country-flags/svg/ni.svg' },
        { countryCode: "+227", countryName: "NE +227", countryFlag: '../assets/svg-country-flags/svg/ne.svg' },
        { countryCode: "+234", countryName: "NG +234", countryFlag: '../assets/svg-country-flags/svg/ng.svg' },
        { countryCode: "+683", countryName: "NU +683", countryFlag: '../assets/svg-country-flags/svg/nu.svg' },
        { countryCode: "+672", countryName: "NF +672", countryFlag: '../assets/svg-country-flags/svg/nf.svg' },
        { countryCode: "+1670", countryName: "MP +1670", countryFlag: '../assets/svg-country-flags/svg/mp.svg' },
        { countryCode: "+47", countryName: "NO +47", countryFlag: '../assets/svg-country-flags/svg/no.svg' },
        { countryCode: "+968", countryName: "OM +968", countryFlag: '../assets/svg-country-flags/svg/om.svg' },
        { countryCode: "+92", countryName: "PK +92", countryFlag: '../assets/svg-country-flags/svg/pk.svg' },
        { countryCode: "+680", countryName: "PW +680", countryFlag: '../assets/svg-country-flags/svg/pw.svg' },
        { countryCode: "+970", countryName: "PS +970", countryFlag: '../assets/svg-country-flags/svg/ps.svg' },
        { countryCode: "+507", countryName: "PA +507", countryFlag: '../assets/svg-country-flags/svg/pa.svg' },
        { countryCode: "+675", countryName: "PG +675", countryFlag: '../assets/svg-country-flags/svg/pg.svg' },
        { countryCode: "+595", countryName: "PY +595", countryFlag: '../assets/svg-country-flags/svg/py.svg' },
        { countryCode: "+51", countryName: "PE +51", countryFlag: '../assets/svg-country-flags/svg/pe.svg' },
        { countryCode: "+63", countryName: "PH +63", countryFlag: '../assets/svg-country-flags/svg/ph.svg' },
        { countryCode: "+64", countryName: "PN +64", countryFlag: '../assets/svg-country-flags/svg/pn.svg' },
        { countryCode: "+48", countryName: "PL +48", countryFlag: '../assets/svg-country-flags/svg/pl.svg' },
        { countryCode: "+351", countryName: "PT +351", countryFlag: '../assets/svg-country-flags/svg/pt.svg' },
        { countryCode: "+1939", countryName: "PR +1939", countryFlag: '../assets/svg-country-flags/svg/pr.svg' },
        { countryCode: "+974", countryName: "QA +974", countryFlag: '../assets/svg-country-flags/svg/qa.svg' },
        { countryCode: "+40", countryName: "RO +40", countryFlag: '../assets/svg-country-flags/svg/ro.svg' },
        { countryCode: "+7", countryName: "RU +7", countryFlag: '../assets/svg-country-flags/svg/ru.svg' },
        { countryCode: "+250", countryName: "RW +250", countryFlag: '../assets/svg-country-flags/svg/rw.svg' },
        { countryCode: "+262", countryName: "RE +262", countryFlag: '../assets/svg-country-flags/svg/re.svg' },
        { countryCode: "+590", countryName: "BL +590", countryFlag: '../assets/svg-country-flags/svg/bl.svg' },
        { countryCode: "+290", countryName: "SH +290", countryFlag: '../assets/svg-country-flags/svg/sh.svg' },
        { countryCode: "+1869", countryName: "KN +1869", countryFlag: '../assets/svg-country-flags/svg/kn.svg' },
        { countryCode: "+1758", countryName: "LC +1758", countryFlag: '../assets/svg-country-flags/svg/lc.svg' },
        { countryCode: "+590", countryName: "MF +590", countryFlag: '../assets/svg-country-flags/svg/mf.svg' },
        { countryCode: "+508", countryName: "PM +508", countryFlag: '../assets/svg-country-flags/svg/pm.svg' },
        { countryCode: "+1784", countryName: "VC +1784", countryFlag: '../assets/svg-country-flags/svg/vc.svg' },
        { countryCode: "+685", countryName: "WS +685", countryFlag: '../assets/svg-country-flags/svg/ws.svg' },
        { countryCode: "+378", countryName: "SM +378", countryFlag: '../assets/svg-country-flags/svg/sm.svg' },
        { countryCode: "+239", countryName: "ST +239", countryFlag: '../assets/svg-country-flags/svg/st.svg' },
        { countryCode: "+966", countryName: "SA +966", countryFlag: '../assets/svg-country-flags/svg/sa.svg' },
        { countryCode: "+221", countryName: "SN +221", countryFlag: '../assets/svg-country-flags/svg/sn.svg' },
        { countryCode: "+381", countryName: "RS +381", countryFlag: '../assets/svg-country-flags/svg/rs.svg' },
        { countryCode: "+248", countryName: "SC +248", countryFlag: '../assets/svg-country-flags/svg/sc.svg' },
        { countryCode: "+232", countryName: "SL +232", countryFlag: '../assets/svg-country-flags/svg/sl.svg' },
        { countryCode: "+65", countryName: "SG +65", countryFlag: '../assets/svg-country-flags/svg/sg.svg' },
        { countryCode: "+421", countryName: "SK +421", countryFlag: '../assets/svg-country-flags/svg/sk.svg' },
        { countryCode: "+386", countryName: "SI +386", countryFlag: '../assets/svg-country-flags/svg/si.svg' },
        { countryCode: "+677", countryName: "SB +677", countryFlag: '../assets/svg-country-flags/svg/sb.svg' },
        { countryCode: "+252", countryName: "SO +252", countryFlag: '../assets/svg-country-flags/svg/so.svg' },
        { countryCode: "+27", countryName: "ZA +27", countryFlag: '../assets/svg-country-flags/svg/za.svg' },
        { countryCode: "+211", countryName: "SS +211", countryFlag: '../assets/svg-country-flags/svg/ss.svg' },
        { countryCode: "+500", countryName: "GS +500", countryFlag: '../assets/svg-country-flags/svg/gs.svg' },
        { countryCode: "+34", countryName: "ES +34", countryFlag: '../assets/svg-country-flags/svg/es.svg' },
        { countryCode: "+94", countryName: "LK +94", countryFlag: '../assets/svg-country-flags/svg/lk.svg' },
        { countryCode: "+249", countryName: "SD +249", countryFlag: '../assets/svg-country-flags/svg/sd.svg' },
        { countryCode: "+597", countryName: "SR +597", countryFlag: '../assets/svg-country-flags/svg/sr.svg' },
        { countryCode: "+47", countryName: "SJ +47", countryFlag: '../assets/svg-country-flags/svg/sj.svg' },
        { countryCode: "+268", countryName: "SZ +268", countryFlag: '../assets/svg-country-flags/svg/sz.svg' },
        { countryCode: "+46", countryName: "SE +46", countryFlag: '../assets/svg-country-flags/svg/se.svg' },
        { countryCode: "+41", countryName: "CH +41", countryFlag: '../assets/svg-country-flags/svg/ch.svg' },
        { countryCode: "+963", countryName: "SY +963", countryFlag: '../assets/svg-country-flags/svg/sy.svg' },
        { countryCode: "+886", countryName: "TW +886", countryFlag: '../assets/svg-country-flags/svg/tw.svg' },
        { countryCode: "+992", countryName: "TJ +992", countryFlag: '../assets/svg-country-flags/svg/tj.svg' },
        { countryCode: "+255", countryName: "TZ +255", countryFlag: '../assets/svg-country-flags/svg/tz.svg' },
        { countryCode: "+66", countryName: "TH +66", countryFlag: '../assets/svg-country-flags/svg/th.svg' },
        { countryCode: "+670", countryName: "TL +670", countryFlag: '../assets/svg-country-flags/svg/tl.svg' },
        { countryCode: "+228", countryName: "TG +228", countryFlag: '../assets/svg-country-flags/svg/tg.svg' },
        { countryCode: "+690", countryName: "TK +690", countryFlag: '../assets/svg-country-flags/svg/tk.svg' },
        { countryCode: "+676", countryName: "TO +676", countryFlag: '../assets/svg-country-flags/svg/to.svg' },
        { countryCode: "+1868", countryName: "TT +1868", countryFlag: '../assets/svg-country-flags/svg/tt.svg' },
        { countryCode: "+216", countryName: "TN +216", countryFlag: '../assets/svg-country-flags/svg/tn.svg' },
        { countryCode: "+90", countryName: "TR +90", countryFlag: '../assets/svg-country-flags/svg/tr.svg' },
        { countryCode: "+993", countryName: "TM +993", countryFlag: '../assets/svg-country-flags/svg/tm.svg' },
        { countryCode: "+1649", countryName: "TC +1649", countryFlag: '../assets/svg-country-flags/svg/tc.svg' },
        { countryCode: "+688", countryName: "TV +688", countryFlag: '../assets/svg-country-flags/svg/tv.svg' },
        { countryCode: "+256", countryName: "UG +256", countryFlag: '../assets/svg-country-flags/svg/ug.svg' },
        { countryCode: "+380", countryName: "UA +380", countryFlag: '../assets/svg-country-flags/svg/ua.svg' },
        { countryCode: "+971", countryName: "AE +971", countryFlag: '../assets/svg-country-flags/svg/ae.svg' },
        { countryCode: "+44", countryName: "GB +44", countryFlag: '../assets/svg-country-flags/svg/gb.svg' },
        { countryCode: "+1", countryName: "US +1", countryFlag: '../assets/svg-country-flags/svg/us.svg' },
        { countryCode: "+598", countryName: "UY +598", countryFlag: '../assets/svg-country-flags/svg/uy.svg' },
        { countryCode: "+998", countryName: "UZ +998", countryFlag: '../assets/svg-country-flags/svg/uz.svg' },
        { countryCode: "+678", countryName: "VU +678", countryFlag: '../assets/svg-country-flags/svg/vu.svg' },
        { countryCode: "+58", countryName: "VE +58", countryFlag: '../assets/svg-country-flags/svg/ve.svg' },
        { countryCode: "+84", countryName: "VN +84", countryFlag: '../assets/svg-country-flags/svg/vn.svg' },
        { countryCode: "+1284", countryName: "VG +1284", countryFlag: '../assets/svg-country-flags/svg/vg.svg' },
        { countryCode: "+1340", countryName: "VI +1340", countryFlag: '../assets/svg-country-flags/svg/vi.svg' },
        { countryCode: "+681", countryName: "WF +681", countryFlag: '../assets/svg-country-flags/svg/wf.svg' },
        { countryCode: "+967", countryName: "YE +967", countryFlag: '../assets/svg-country-flags/svg/ye.svg' },
        { countryCode: "+260", countryName: "ZM +260", countryFlag: '../assets/svg-country-flags/svg/zm.svg' },
        { countryCode: "+263", countryName: "ZW +263", countryFlag: '../assets/svg-country-flags/svg/zw.svg' },
    ];

}

