import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public form: FormGroup;
    public loader = false;
    rememberMeControl = new FormControl(false);

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private localStorage: LocalStorageService,
        public api: ApiService
    ) {
        this.form = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.pattern(this.api.constant.EMAIL_REGEX)])],
            password: ['', Validators.required]
        });
    }

    ngOnInit() {
        if (this.localStorage.getItem('rememberMe')) {
            this.rememberMeControl.patchValue(true);
            this.form.patchValue((this.localStorage.getItem('rememberData')));
        }
    }

    loginFun() {
        if (this.form.valid) {
            this.loader = true;

            this.localStorage.removeItem('subadminClubid');

            this.api.postData(this.api.apiUrl.login, this.form.value)
                .subscribe(serverResponse => {
                    if (this.rememberMeControl.value) {
                        this.localStorage.setItem('rememberMe', this.rememberMeControl.value);
                        this.localStorage.setItem('rememberData', JSON.stringify(this.form.value));
                    } else {
                        this.localStorage.removeItem('rememberMe');
                        this.localStorage.removeItem('rememberData');
                        this.localStorage.removeItem('rememberData');
                        this.localStorage.removeItem('subadminClubid');
                        this.localStorage.removeItem('clubLogo');
                        this.localStorage.removeItem('savedFilters');
                    }

                    this.userService.setUserLocalData(serverResponse.data);

                    // if (this.form.value.email === 'staff@grintafy.com') {
                    //     this.api.navigate('dashboard', '');
                    // } else if (this.form.value.email === 'broadcast@grintafy.com') {
                    //     this.api.navigate('broadcast', '');
                    // }
                    if (serverResponse.data.landingTo) {
                        this.api.navigate(serverResponse.data.landingTo, '');
                    } else {
                        localStorage.setItem('clubLogo', 'assets/images/5.png');
                        //Club ID
                        let subadminClubidVal = "0";
                        try {
                            subadminClubidVal = serverResponse.data.clubAccount._id;
                            localStorage.setItem('subadminClubid', subadminClubidVal);

                            //Store logo
                            localStorage.setItem('clubLogo', serverResponse.data.clubAccount.profilePicURL.thumbnail);


                        }
                        catch (e) {

                        }
                        if (this.form.value.email === 'test@grintafy.com') {
                            this.api.navigate('club-access', '');
                        } else if (this.form.value.email === 'ahmad@grintafy.com') {
                            this.api.navigate('main', '');
                        }
                        else if (this.form.value.email === 'ziad@grintafy.com') {
                            this.api.navigate('main', '');
                        } {
                            if (this.userService.hasPermission(['dashboard.graph', 'dashboard.online', 'dashboard.stats', 'dashboard.bookBallBrag'])) {
                                if (this.form.value.email === 'test@grintafy.com') {
                                    this.api.navigate('club-access', '');
                                } else if (this.form.value.email === 'ahmad@grintafy.com') {
                                    this.api.navigate('main', '');
                                } else if (this.form.value.email === 'ziad@grintafy.com') {
                                    this.api.navigate('main', '');
                                } else {
                                    this.api.navigate('dashboard', '');
                                }
                            } else {
                                const routes = serverResponse.data.permissions[0].split('.');
                                this.api.navigate(routes.length > 1 ? routes[1] : routes[0], '');
                            }
                        }

                    }
                },
                    () => {
                        this.loader = false;
                    });
        } else {
            Object.keys(this.form.controls).forEach(key => {
                this.form.controls[key].markAsTouched({ onlySelf: true });
            });
        }
    }

}
