import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
    public loader = false;
    email: any;
    lblforgotpasswordstatus: any;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        public api: ApiService
    ) {
        
    }

    ngOnInit() {
        
    }
 
    forgotFun()
    {
      
        const obj: any = {};
        obj.email=this.email;
        obj.app="2";
        obj.language="EN";
        this.api.postData(this.api.apiUrl.getforgotpasswordlink, obj)
        .subscribe(res => 
        {
          
          if (res.statusCode==200)
          this.lblforgotpasswordstatus="Please Check your mailbox include spam folder and use link to reset your password";
         
          if (res.statusCode==400)
          this.lblforgotpasswordstatus=res.message;

        },
        () => {
        });

    }

}
