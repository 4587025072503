export const apiUrl = {
    playerposts: 'admin/playerPosts',
    playerDashboard: 'admin/playerDashboard',
    playerBookingList: 'admin/PlayersBookingList',
    addAmenity: 'admin/addAmenities',
    addCity: 'admin/addCities',
    addField: 'admin/addField',
    addFieldSize: 'admin/addFieldsize',
    addGroundType: 'admin/addGroundTypes',
    addMatchType: 'admin/addMatchTypes',
    addPosition: 'admin/addPosition',
    addSponsor: 'admin/addSponsors',
    addToSubscribers: 'admin/addToSubscribers',
    addVersion: 'admin/addVersion',
    gliveStatus: 'admin/gliveStatus',
    gliveAction: 'admin/gliveAction',
    addCountry: 'admin/addcountry',
    addState: 'admin/addstates',
    adminEarning: 'admin/adminEarning',
    blockField: 'admin/blockField',
    blockUnblockData: 'admin/blockUnblockData',
    favouritePlayer: 'admin/favoritePlayer',
    broadcastMessage: 'admin/broadcastMessage',
    newPopUp: 'admin/newPopUp',
    popUp: 'admin/popUp',
    editPopUp: 'admin/editPopUp',
    gameLinkValidate: 'admin/broadcast/validate',
    broadcastsearchPlayer: 'admin/broadcast/searchPlayer',
    promoSearchplayer: 'admin/promo/searchPlayer',
    broadcastSearchFeed: 'admin/broadcast/searchFeed',
    contactDetails: 'admin/contactDetails',
    addContract: 'admin/createContract',
    addAdvertisement: 'admin/createEditAdvertisement',
    addFeature: 'admin/addEditLiveStreams',
    featuredList: 'admin/getLiveStreams',
    addEditAdvertisement: 'admin/addEditAdvertisement',
    addPromoCode: 'admin/createEditPromoCode',
    addOwner: 'admin/createOwnerByAdmin',
    dashBoard: 'admin/dashBoard',
    gplusStats: 'admin/gplus/stats',
    gplusSubscribeStats: 'admin/gplus/subscribeStats',
    gplusDateGraph: 'admin/gplus/dateGraph',
    gplusCityGraph: 'admin/gplus/cityGraph',
    gplusCountryCancelGraph: 'admin/gplus/cancelGraph',
    gplusMapData: 'admin/gplus/mapData',
    gplusListing: 'admin/gplus/listing',
    gplusListingHistory: 'admin/gplus/listing/history',
    deleteAmenity: 'admin/deleteAmenities',
    deleteContract: 'admin/deleteContract',
    deleteData: 'admin/deleteData',
    deleteFieldSize: 'admin/deleteFieldSize',
    deleteFieldType: 'admin/deleteFieldType',
    deleteLocality: 'admin/deleteLocality',
    deleteMatchType: 'admin/deleteMatchType',
    deletePosition: 'admin/deletePosition',
    deleteQuery: 'admin/deleteQueries',
    deleteReportedPost: 'admin/deleteReportedPost',
    fieldDetails: 'admin/fieldDetails',
    gameDetails: 'admin/gameDetails',
    getAdRevenueDetails: 'admin/getAdRevenueDatals',
    ads: 'admin/getAds',
    getAdvertisements: 'admin/getAdvertisements',
    publishAd: 'admin/approveAd',
    broadCasts: 'admin/getBroadCast',
    cities: 'owner/citiesList1',
    getCompleteRevenueData: 'admin/getCompleteRevenueData',
    contracts: 'admin/getContracts',
    countries: 'admin/getCountryList',
    getFieldData: 'admin/getFieldData',
    getListing: 'admin/getListing',
    getMailChimpList: 'admin/getMailChimpList',
    owners: 'admin/getOwnerList',
    promoCodes: 'admin/getPromoCode',
    queries: 'admin/getQueries',
    revenueData: 'admin/getRevenueData',
    getSlotsPromotion: 'admin/getSlotsPromotion',
    sponsors: 'admin/getSponsors',
    states: 'admin/getStatesList',
    getSubscribers: 'admin/getSubscribers',
    getGliveQueries: 'admin/getGliveQueries',
    getGAdvertiseQueries: 'admin/getAdQueries',
    getCvQueries: 'admin/getApplyQueries',
    getFieldWithContract: 'admin/getfieldWithContract',
    fieldSizes: 'admin/listFieldSize',
    fieldTypes: 'admin/listFieldType',
    matchTypes: 'admin/listMatchTypes',
    listPickupGames: 'admin/listPicupGames',
    listPlayers: 'admin/listPlayers',
    playersData: 'admin/playersData',
    listReportedPost: 'admin/listReportedPost',
    listVersion: 'admin/listVersion',
    login: 'admin/login',
    pickUpGameCount: 'admin/pickupgameCount',
    postQueries: 'admin/postQueries',
    subscribeMailChimp: 'admin/subscribeMailChimp',
    updateCity: 'admin/updateCity',
    updateCountry: 'admin/updateCountry',
    updateFieldSize: 'admin/updateFieldSize',
    updateFieldType: 'admin/updateFieldType',
    updateMatchType: 'admin/updateMatchType',
    updatePasswordViaLink: 'admin/updatePasswordViaLink',
    updatePlayerProfile: 'admin/updatePlayerProfile',
    updateProfile: 'admin/updateProfile',
    updateState: 'admin/updateState',
    uploadImage: 'admin/uploadImageOrVideo',
    amenities: 'owner/getAmenities',
    positions: 'owner/listPosition',
    checkUsername: 'owner/checkUsername',
    reports: 'admin/getReports',
    playerReport: 'admin/playerReport',
    groundRelatedData: 'owner/groundRelatedData',
    generateReport: 'admin/generateReport',
    getOwnerQueries: 'admin/getOwnerQueries',
    newDashboard: 'admin/newDashboard',
    gameDashboard: 'admin/gameDashboard',
    dashboardGGOvsOGGO: 'admin/dashboardGGOvsOGGO',
    feedDashboard: 'admin/feedDashboard',
    addCamerasToField: 'admin/addCamerasToField',
    fetchReferrals: 'admin/fetchReferrals',
    getGameDetails: 'admin/gameDetails',
    getCurrencies: 'admin/getCurrencies',
    updateGame: 'admin/editGame',
    addEditBrands: 'admin/addEditBrands',
    addEditClubs: 'admin/addEditClubs',
    addEditCurrencies: 'admin/addEditCurrencies',
    verifyOrganizer: 'admin/verifiedOrganizer',
    getRegistrationReport: 'admin/getRegistrationReport',
    getAdmins: 'admin/getAdmins',
    addEditAdmin: 'admin/addEditAdmin',
    blockUnblockAdmin: 'admin/blockUnblockData',
    updateGameStatus: 'admin/checkUncheckGame',
    /* New */
    bookingDashboard: 'admin/bookingDashboard',
    newDashboardV2: 'admin/newDashboardV2',
    getOnlineUsers: 'admin/onlineUsers',
    onlineUsers: 'admin/onlineUsersV2',
    newDashboardV3: 'admin/newDashboardV3',
    newDashboardV4: 'admin/newDashboardV4',
    // Graph
    registrationGraph: "admin/registrationGraph",
    gameGraph: "admin/gameGraph",
    fieldgraph: "admin/feedGraph",
    bookingGraph: "admin/bookingGraph",
    overallGraph: "admin/overallGraph",
    operationKPIGraph: 'admin/operationKPI',
    marketingDashboard: 'admin/marketingKPI',
    playersKpiDashboard: 'admin/playerKPI',
    playersLatLng: 'admin/playerLocations',
    overallKPI: 'admin/overallKPI',
    overallKPI2023: 'admin/overallKPI2023',
    egyptKPI: 'admin/egyptKPI',
    egyptKPI2023: 'admin/egyptKPI2023',
    /* new */

    /* Dashboard 2024 */
    monthlyGames: 'admin/overall/monthly/games',
    monthlyRegistrations: 'admin/overall/monthly/registrations',
    monthlyLastSeen: 'admin/overall/monthly/lastSeen',
    monthlyFeeds: 'admin/overall/monthly/feeds',

    gameschartgameGraphV2: "admin/gameGraphV2",
    registrationGraphV2: "admin/registrationGraphV2",
    feedGraphV2: "admin/feedGraphV2",
    bookingGraphV2: "admin/bookingGraphV2",
    overallGraphV2: "admin/overallGraphV2",
    registrationGraphV3: "admin/registrationGraphV3",
    overAllOnlineGames: 'admin/onlineGameGraph',
    pointingSystemjPoints: 'admin/achievement/points',
    pointingSystemPointGraph: 'admin/achievement/pointGraph',
    // South Africa
    addSaPlayer: "admin/za/player",
    getSouthAfricaPlayerData: "admin/za/playersData",
    //tryout
    getClubAccounts: "admin/getClubAccounts",
    getUpcomingTryouts: "admin/getUpcomingTryouts",
    getTryoutDetails: "admin/getTryoutDetails",
    getTryoutPlayerData: "admin/playersData",
    getacceptRejectRequest: "admin/acceptRejectRequest",
    getPastTryouts: "admin/getPastTryouts",
    getlockUnlockPlayer: "admin/lockUnlockPlayer",
    getPlayersTryOuts: "admin/getPlayersTryOuts",
    getapproveDeclineForTryout: "admin/approveDeclineForTryout",
    getsearchclubPlayers: "admin/searchPlayer",
    getaddPlayerstoclub: "admin/addPlayersToClub",
    exportTryOuts: 'admin/getUpcomingTryouts',
    addSurvey: "admin/addSurvey",
    editSurvey: "admin/editSurvey",
    getSurvey: "admin/getSurvey",
    deleteSurvey: "admin/deleteSurvey",
    getSurveyAnswers: 'admin/getSurveyAnswers',
    getSurveyShortAnswers: 'admin/getSurveyShortAnswers',
    //ggo-reports
    getSearchplayers: "admin/searchPlayer",
    getmarkUnmarkGGO: "admin/markUnmarkGGO",
    getGGOReport: "admin/getGGOReport",
    getGGOReportV2: "admin/getGGOReportV2",
    getAcademyRegisteration: 'admin/getAcademyRegistrations',

    //forgot-password
    getforgotpasswordlink: "player/forgetPassword",

    //target
    getOverallTargetReport: "admin/getOverallTargetReport",

    //stc-online-games
    getstcGames: 'admin/getstcGames',
    getstcGamePlayers: 'admin/getstcGamePlayers',
    pausestcCashOut: 'admin/pausestcCashOut',

    updateFeed: 'admin/updateFeed',
    resolveQuery: 'admin/resolveQuery',
    markUnmarkFeatured: 'admin/markUnmarkFeatured',
    geSuccessStories: 'admin/getSuccessStory',
    manageSuccessStories: 'admin/manageSuccessStory',
    // app dowloads
    getAppDownloads: 'admin/getAppDownloads',
    addEditDownload: 'admin/addEditAppDownloads',
    ediBookingDetails: 'admin/editBookingDetail',
    getFeedCategories: 'admin/getFeedCategories',
    mojtameeAcademyRegisteration: 'admin/academyRegistrations',
    fetchAcademies: 'admin/academies',
    AcademyRegister: 'admin/academy/register',

    //challanges apis
    ChallangesApi: 'admin/saff/challenge?type=',
    moveFeed: 'admin/saff/moveFeed',
    challangeDetails: 'admin/saff/feed?type=',
    firstFilter: 'admin/saff/firstFilter',
    saffFavoritePlayer: 'admin/saff/favoritePlayer',
    updateChallengeStatus: 'admin/saff/feedManage',
    deleteFeed: 'admin/saff/feedDelete',

    //Scout Users
    addEditScout: 'admin/addEditScout',
    getScouts: 'admin/getScouts',
    blockUnblockScout: 'admin/blockUnblockData',
    uploadScoutImage: 'scout/upload',
    scoutFileRemove: 'scout/fileRemove',
    onlineGameDashboard: 'admin/onlineGameDashboard',
    onlineGameYearly: 'admin/onlineGameYearly',
    onlineGameMonthly: 'admin/onlineGameMonthly',
    onlineGameDaily: 'admin/onlineGameDaily',
    playedGameDashboard: 'admin/playedGamesDashboard',
    playedGameYearly: 'admin/playedGamesYearly',
    playedGameMonthly: 'admin/playedGamesMonthly',
    playedGameWeekly: 'admin/playedGamesWeekly',
    promoCodesForOnlineGames: 'admin/getPromo',
    addPromoCodes: 'admin/addPromo',
    deleteOnlinePromo: 'admin/managePromo',
    editPromoCodes: 'admin/editPromo',

    // Camps Players
    getCampPlayerList: "admin/camp/playerList",
    // G+
    gPlusSearchPlayer: "admin/gplus/searchPlayer",
    freePackage: "admin/gplus/freePackage",
    freePlayers: "admin/gplus/freePlayers",
    editFreePackage: "admin/gplus/editFreePackage",
    stopFreePackage: "admin/gplus/stopFreePackage",
    overall_kpi_2024: "admin/overallKPI2024",
    overall_kpi_q4_2024: "admin/overallKPI2024Quarter4"
};


