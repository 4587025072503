import {Injectable} from '@angular/core';
import {LocalStorageService} from './local-storage.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(
        private router: Router,
        private localStorage: LocalStorageService
    ) {

        /********* Check JSON parse error on fetching currentUser from local storage **********/
        let _user: any = null;
        try {
            _user = this.localStorage.getItem('web_user');
        } catch (error) {
            if (error instanceof SyntaxError) this.removeUser();
        }
        this.currentUserSubject = new BehaviorSubject<any>(_user);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    /********* Get the current value of the logged in user **********/
    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    /********* Get the current user token **********/
    public get getUserToken(): string {
        if (!!this.currentUserValue) return this.currentUserValue.access_token;
    }

    /********* Set user in local storage **********/
    public setUserLocalData(userData: any) {
        this.localStorage.setItem('web_user', JSON.stringify(userData));
        this.currentUserSubject.next(userData);
    }

    public hasPermission(permissions: string | string[]): boolean {
        if (!permissions || Array.isArray(permissions) && !permissions.length) {
            return true; // every role has blank permission
        }

        const currentUser = this.currentUserValue;
        if (!currentUser) {
            return false;
        }

        if (currentUser.superAdmin) {
            return true;
        }

        if (!currentUser.permissions.length) {
            return false;
        }

        if (typeof permissions === 'string') {
            return !!currentUser.permissions.find((item) => item.toLowerCase() === permissions.toLowerCase());
        }

        for (const permission of permissions) {
            const value = currentUser.permissions.find((item) => item.toLowerCase() === permission.toLowerCase());
            if (value) {
                return true;
            }
        }

        return false;
    }

    /********* Remove user from local storage **********/
    public removeUser() {
        this.localStorage.removeItem('web_user');
        if (this.currentUserSubject) {
            this.currentUserSubject.next(null);
        }
    }

    /********* User Sign-Out **********/
    public userSignOut() {
        this.removeUser();
        this.router.navigate(['/login']);
    }
}
