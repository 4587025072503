import { Color } from "ng2-charts/lib/color";
import { ajaxGet } from "rxjs/internal-compatibility";

export const CropSizes = {
  field: { first: 1384, sec: 593 },
  feed: { first: 1307, sec: 692 },
  popup: { first: 1334, sec: 980 },
  banner: { first: 343, sec: 108 },
};

export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

//export const EGYPT_COUNTRYID = '5dcf9c7ac26ed6285f55315d'; //TEST_SERVER_EGYPT_COUNTRYID
export const EGYPT_COUNTRYID = "5c8d4dfa72b7100fe06eb568"; //LIVE_SERVER_EGYPT_COUNTRYID

export const ARABIC = "[\u0600-\u06FF]";
// export const ARABIC = '[أ-ي]';
// export const ARABIC = '^[\p{Arabic}\s\p{N}]+$';
export const ENGLISH = "^[a-zA-Z ]+$";

export const AgeGroup = [
  { name: "U 13", value: "13", isSelected: false },
  { name: "U 14", value: "14", isSelected: false },
  { name: "U 15", value: "15", isSelected: false },
  { name: "U 16", value: "16", isSelected: false },
  { name: "U 17", value: "17", isSelected: false },
  { name: "U 18", value: "18", isSelected: false },
  { name: "U 19", value: "19", isSelected: false },
  { name: "U 20", value: "20", isSelected: false },
  { name: "20 - 24", value: "2024", isSelected: false },
  { name: "24+ (senior)", value: "24", isSelected: false },
];

export const sideBarAdmin = [
  // {
  //     path: '/dashboard',
  //     icon: 'fa fa-th-large',
  //     children: [],
  //     title: 'Dashboard',
  //     permissions: ['superAdmin', 'dashboard.online', 'dashboard.graph', 'dashboard.stats']
  // },
  {
    path: "stats",
    icon: "fa fa-th-large",
    // children: [],
    title: "Dashboard",
    permissions: [
      "superAdmin",
      "main-kpi",
      "dashboard.graph",
      "dashboard.stats",
      "overall-dashboard",
      "overall-kpi",
      "overall-kpi-q1-2025",
      "overall-kpi-q4",
      //   "grintafy-plus-dashboard",
      "overall-kpi-q1-2023",
      "overall-kpi-q2-2023",
      "overall-kpi-q3-2023",
      "game-dashboard",
      "played-game-dashboard",
      "achievement-dashboard",
      "online-game-dashboard",
      //   "feed-dashboard",
      "booking-dashboard",
      "marketing-kpi",
      "player-kpi",
      "draft-dashboard",
      "dashboard.kpi",
      "operation-kpi",
      // "club-access",
      "ggo-vs-oggo",
      "v2-overall-dashboard",
      "dashboard",
      "overall-kpi-q3-2024"
    ],

    children: [
      // {
      //   path: "/main",
      //   icon: "",
      //   children: [],
      //   title: "Main ",
      //   permissions: ["superAdmin", "main-kpi"],
      // },
      {
        path: "/dashboard",
        icon: "",
        children: [],
        title: "Overall ",
        permissions: ["superAdmin", "dashboard.graph", "dashboard.stats", "dashboard"],
      },
      {
        path: "/kpi-q1-dashboard-2025",
        icon: "",
        children: [],
        title: "KPIs Q1-2025 Dashboard",
        permissions: ["superAdmin", "overall-kpi-q1-2025"],
      },
      {
        path: "/overall-kpi-q3-2024",
        icon: "",
        children: [],
        title: "KPIs Q3-2024 Dashboard",
        permissions: ["superAdmin", "overall-kpi-q3-2024"],
      },
      {
        path: "/overall-kpi-q4-2024",
        icon: "",
        children: [],
        title: "KPIs Q4-2024 Dashboard",
        permissions: ["superAdmin", "overall-kpi-q4-2024"],
      },
      // {
      //   path: "/v2-overall-dashboard",
      //   icon: "",
      //   children: [],
      //   title: "V2 Overall Dashboard",
      //   permissions: ["superAdmin", "v2-overall-dashboard"],
      // },
      // {
      //   path: "/over-all-dashboard",
      //   icon: "",
      //   children: [],
      //   title: "Over All Dashboard ",
      //   permissions: ["superAdmin", "overall-dashboard"],
      // },
      // {
      //   path: "/overall-KPI-dashboard",
      //   icon: "",
      //   children: [],
      //   title: "Q3-2022 KPIs Dashboard",
      //   permissions: ["superAdmin", "overall-kpi"],
      // },
      // {
      //   path: "/overall-KPI-dashboard-q4",
      //   icon: "",
      //   children: [],
      //   title: "Q4-2022 KPIs Dashboard",
      //   permissions: ["superAdmin", "overall-kpi-q4"],
      // },
      // {
      //   path: "/overall-KPI-dashboard-q1",
      //   icon: "",
      //   children: [],
      //   title: "Q1-2023 KPIs Dashboard",
      //   permissions: ["superAdmin", "overall-kpi-q1-2023"],
      // },
      // {
      //   path: "/overall-KPI-dashboard-q2",
      //   icon: "",
      //   children: [],
      //   title: "Q2-2023 KPIs Dashboard",
      //   permissions: ["superAdmin", "overall-kpi-q2-2023"],
      // },
      // {
      //   path: "/overall-KPI-dashboard-q3",
      //   icon: "",
      //   children: [],
      //   title: "Q3-2023 KPIs Dashboard",
      //   permissions: ["superAdmin", "overall-kpi-q3-2023"],
      // },
      // {
      //   path: "/overall-KPI-dashboard-2023-q4",
      //   icon: "",
      //   children: [],
      //   title: "Q4-2023 KPIs Dashboard",
      //   permissions: ["superAdmin", "overall-kpi-q4-2023"],
      // },
      // {
      //   path: "/game-dashboard",
      //   icon: "",
      //   children: [],
      //   title: "Game Dashboard",
      //   permissions: ["superAdmin", "game-dashboard"],
      // },
      // {
      //   path: "/active-users-games",
      //   icon: "",
      //   children: [],
      //   title: "Active Users Game",
      //   permissions: ["superAdmin", "played-game-dashboard"],
      // },
      // {
      //   path: "/poniting-system",
      //   icon: "",
      //   children: [],
      //   title: "Pointing System",
      //   permissions: ["superAdmin", "achievement-dashboard"],
      // },
      //   {
      //     path: "/grintafy-plus-dashboard",
      //     icon: "",
      //     children: [],
      //     title: "Grintafy Plus Dashboard",
      //     permissions: ["superAdmin", "grintafy-plus-dashboard"],
      //   },
      // {
      //   path: "/online-games-dashboard",
      //   icon: "",
      //   children: [],
      //   title: "Online Games Dashboard",
      //   permissions: ["superAdmin", "online-game-dashboard"],
      // },
      //   {
      //     path: "/feed-dashboard",
      //     icon: "",
      //     children: [],
      //     title: "Feed Dashboard",
      //     permissions: ["superAdmin", "feed-dashboard"],
      //   },
      // {
      //   path: "/booking-dashboard",
      //   icon: "",
      //   children: [],
      //   title: "Booking Dashboard",
      //   permissions: ["superAdmin", "booking-dashboard"],
      // },
      // {
      //   path: "/marketing-dashboard",
      //   icon: "",
      //   children: [],
      //   title: "Marketing Dashboard",
      //   permissions: ["superAdmin", "marketing-kpi"],
      // },
      // {
      //   path: "/players-kpi",
      //   icon: "",
      //   children: [],
      //   title: "Players Dashboard",
      //   permissions: ["superAdmin", "player-kpi"],
      // },
      // {
      //   path: "/draft-dashboard",
      //   icon: "",
      //   children: [],
      //   title: "Draft Dashboard",
      //   permissions: ["superAdmin", "draft-dashboard"],
      // },
      // {
      //   path: "/target-dashboard",
      //   icon: "",
      //   children: [],
      //   title: "KPI Dashboard",
      //   permissions: ["superAdmin", "dashboard.kpi"],
      // },
      // {
      //   path: "/operation-kpi",
      //   icon: "",
      //   children: [],
      //   title: "Operation KPIs",
      //   permissions: ["superAdmin", "operation-kpi"],
      // },
      // {
      //   path: "/club-access",
      //   icon: "",
      //   children: [],
      //   title: "Club Access",
      //   permissions: ["superAdmin", "club-access"],
      // },
      // {
      //   path: "/ggos-oggos-dashboard",
      //   icon: "",
      //   children: [],
      //   title: "Pgos vs Oggs Dashboard",
      //   permissions: ["superAdmin", "ggo-vs-oggo"],
      // },
    ],
  },
  // {
  //   path: "stats",
  //   icon: "fa fa-th-large",
  //   // children: [],
  //   title: "Dashboard 2024",
  //   permissions: ["superAdmin", "overall-dashboard-2024"],
  //   children: [
  //     {
  //       path: "/dashboard-2024",
  //       icon: "",
  //       children: [],
  //       title: "Overall ",
  //       permissions: ["superAdmin", "overall-dashboard-2024"],
  //     },
  //   ],
  // },
  // {
  //     path: 'stats',
  //     icon: 'fa fa-th-large',
  //     // children: [],
  //     title: 'Egypt Dashboard',
  //     permissions: ['egypt-dashboard'],
  //     children: [
  //         /*{
  //             path: '/main', icon: '', children: [],
  //             title: 'Main ', permissions: ['superAdmin', 'main-kpi']
  //         },*/
  //         {
  //             path: '/egypt-dashboard', icon: '', children: [],
  //             title: 'Overall ', permissions: ['egypt-dashboard']
  //         },
  //         /*{
  //             path: '/egypt-kpi-dashboard-q3-2023', icon: '', children: [],
  //             title: 'Egypt Q3-2023 KPI Dashboard', permissions: ['egypt-kpi-q3-2023']
  //         },*/
  //         {
  //             path: '/egypt-kpi-dashboard', icon: '', children: [],
  //             title: 'Egypt Q3 KPI Dashboard', permissions: ['egypt-kpi']
  //         },
  //         {
  //             path: '/egypt-kpi-dashboard-q4', icon: '', children: [],
  //             title: 'Egypt Q4 KPI Dashboard', permissions: ['egypt-kpi-q4']
  //         },
  //         /*{
  //             path: '/egypt-kpi-dashboard-q1', icon: '', children: [],
  //             title: 'Egypt Q1 KPI Dashboard', permissions: ['egypt-kpi-q4']
  //         },*/
  //         /*{
  //             path: '/overall-KPI-dashboard', icon: '', children: [],
  //             title: 'Overall KPI ', permissions: ['superAdmin','overall-kpi']
  //         },*/
  //         {
  //             path: '/egypt-game-dashboard', icon: '',
  //             children: [], title: 'Game Dashboard',
  //             permissions: ['egypt-dashboard']
  //         },
  //         {
  //             path: '/egypt-feed-dashboard', icon: '', children: [], title: 'Feed Dashboard',
  //             permissions: ['egypt-dashboard']
  //         },
  //         /*{
  //             path: '/booking-dashboard', icon: '', children: [], title: 'Booking Dashboard',
  //             permissions: ['superAdmin', 'dashboard.graph', 'dashboard.stats']
  //         },
  //         {
  //             path: '/marketing-dashboard', icon: '', children: [], title: 'Marketing Dashboard',
  //             permissions: ['superAdmin', 'marketing-kpi']
  //         },*/
  //         {
  //             path: '/egypt-players-kpi', icon: '', children: [], title: 'Players Dashboard',
  //             permissions: ['egypt-dashboard']
  //         },
  //         /*{
  //             path: '/target-dashboard', icon: '', children: [], title: 'KPI Dashboard',
  //             permissions: ['superAdmin', 'dashboard.kpi']
  //         },
  //         {
  //             path: '/operation-kpi', icon: '', children: [], title: 'Operation KPIs',
  //             permissions: ['superAdmin', 'operation-kpi']
  //         },
  //         {
  //             path: '/club-access', icon: '', children: [], title: 'Club Access',
  //             permissions: ['superAdmin', 'club-access']
  //         }*/

  //     ]
  // },
  {
    path: "owners",
    icon: "fa fa-flag",
    title: "Owner Data",
    permissions: [
      "superAdmin",
      "owner.fields",
      "owner.owners",
      "owner.contracts",
    ],
    children: [
      {
        path: "/fields",
        icon: "",
        children: [],
        title: "Court List",
        permissions: ["superAdmin", "owner.fields"],
      },
      {
        path: "/owners",
        icon: "",
        children: [],
        title: "Owner List",
        permissions: ["superAdmin", "owner.owners"],
      },
      {
        path: "/contracts",
        icon: "",
        children: [],
        title: "Contract List",
        permissions: ["superAdmin", "owner.contracts"],
      },
    ],
  },
  {
    path: "players",
    icon: "fa fa-users",
    title: "Player data",
    permissions: [
      "superAdmin",
      "player.players",
      "player.details",
      "player.posts",
      "player.videos",
    ],
    children: [
      {
        path: "/players",
        icon: "",
        children: [],
        title: "Players",
        permissions: ["superAdmin", "player.players"],
      },
      {
        path: "/player-details",
        icon: "",
        children: [],
        title: "Player Details",
        permissions: ["superAdmin", "player.details"],
      },
      // {
      //   path: "/posts",
      //   icon: "",
      //   children: [],
      //   title: "Posts",
      //   permissions: ["superAdmin", "player.posts"],
      // },
      // {
      //   path: "/player-videos",
      //   icon: "",
      //   children: [],
      //   title: "Videos",
      //   permissions: ["superAdmin", "player.videos"],
      // },
      // {
      //     path: '/pick-up-games',
      //     icon: '',
      //     children: [],
      //     title: 'Pick Up Games',
      //     permissions: ['superAdmin', 'player.pick-up-games']
      // }
    ],
  },
  // {
  //   path: "/sa-players",
  //   icon: "fa fa-users",
  //   title: "Registration Information",
  //   permissions: ["superAdmin", "registration-information"],
  //   children: [],
  // },
  // {
  //     path: 'tryout',
  //     icon: 'fa fa-users',
  //     title: 'Tryout Information',
  //     permissions: ['superAdmin', 'tryout-information'],
  //     children: [
  //         { path: '/sa-tryout', icon: '', children: [], title: 'Upcoming Tryouts', permissions: ['superAdmin', 'tryout-information'] },
  //         { path: '/sa-tryout-finished', icon: '', children: [], title: 'Completed Tryouts', permissions: ['superAdmin', 'tryout-information'] },
  //         { path: '/sa-tryoutfinalplayer/accepted', icon: '', children: [], title: 'Tryouts  Players', permissions: ['superAdmin', 'tryout-information'] },

  //     ]
  // },
  // {
  //   path: "discover/success-stories",
  //   icon: "fa fa-users",
  //   title: "Discover Home-page",
  //   permissions: ["superAdmin", "player.featured-content", "success-story"],
  //   children: [
  //     {
  //       path: "/discover/success-stories",
  //       icon: "",
  //       children: [],
  //       title: "Success-Stories",
  //       permissions: ["superAdmin", "success-story"],
  //     },
  //     {
  //       path: "/discover/videos",
  //       icon: "",
  //       children: [],
  //       title: "Videos",
  //       permissions: ["superAdmin", "player.featured-content"],
  //     },
  //   ],
  // },

  // {
  //     path: '/mujtamaee-dashboard',
  //     icon: 'fa fa-th-large',
  //     title: 'Mujtamaee Dashboard',
  //     permissions: ['superAdmin', 'mujtamaee.dashboard'],
  //     children: []
  // },
  // {
  //   path: "grinta-competition",
  //   icon: "fa fa-th-large",
  //   title: "TDC",
  //   permissions: ["superAdmin", "grintador", "grintador2"],
  //   children: [
  //     {
  //       path: "/grinta-competition",
  //       icon: "",
  //       children: [],
  //       title: "PalaDe 7 Challenges",
  //       permissions: ["superAdmin", "grintador"],
  //     },

  //     {
  //       path: "/grinta-competition2",
  //       icon: "",
  //       children: [],
  //       title: "PalaDe 7 + Challenges",
  //       permissions: ["superAdmin", "grintador2"],
  //     },
  //   ],
  // },
  // {
  //     path: '/uploaded-videos',
  //     icon: 'fa fa-th-large',
  //     title: 'SAFF Competition',
  //     permissions: ['superAdmin', 'saff-challenge'],
  //     children: []
  // },
  // {
  //     path: '/players-filtration',
  //     icon: 'fa fa-th-large',
  //     title: 'SAFF Access Competition',
  //     permissions: ['superAdmin', 'saff-competition-access-page'],
  //     children: []
  // },
  // {
  //     path: '/mojtaee-academies',
  //     icon: 'fa fa-th-large',
  //     title: 'Mojtaee Academies',
  //     permissions: ['superAdmin', 'mujtamaee-academy'],
  //     children: []
  // },
  //Pickup games
  {
    path: "pick-up-games",
    icon: "fa fa-users",
    title: "Pick Up Games",
    permissions: [
      "superAdmin",
      "player.pick-up-games",
      "player.pick-up-games.online-games",
    ],
    children: [
      {
        path: "/pick-up-games",
        icon: "",
        children: [],
        title: "Cash games",
        permissions: ["superAdmin", "player.pick-up-games"],
      },

      {
        path: "/online-games",
        icon: "",
        children: [],
        title: "Online games",
        permissions: ["superAdmin", "player.pick-up-games.online-games"],
      },
      {
        path: "/free-games",
        icon: "",
        children: [],
        title: "Free games",
        permissions: ["superAdmin", "player.pick-up-games.online-games"],
      },
    ],
  },

  // {
  //     path: 'tryout',
  //     icon: 'fa fa-users',
  //     title: 'Tryouts',
  //     permissions: ['superAdmin', 'tryout.tryout', 'tryout.players'],
  //     children: [
  //         { path: '/tryout', icon: '', children: [], title: 'Upcoming Tryouts', permissions: ['superAdmin', 'tryout.tryout'] },
  //         { path: '/tryout-finished', icon: '', children: [], title: 'Completed Tryouts', permissions: ['superAdmin', 'tryout.players'] },
  //         { path: '/tryoutfinalplayer/accepted', icon: '', children: [], title: 'Tryouts  Players', permissions: ['superAdmin', 'tryout.players'] },

  //     ]
  // },

  // {
  //   path: "tryout-club-players",
  //   icon: "fa fa-address-book",
  //   title: "Club Data",
  //   permissions: ["superAdmin", "tryout.players", "camp-players"],
  //   children: [
  //     {
  //       path: "/tryout-club-players",
  //       icon: "",
  //       children: [],
  //       title: "Club  Players",
  //       permissions: ["superAdmin", "tryout.players"],
  //     },
  //     /*{ path: '/campsPlayers', icon: '', children: [], title: 'Camps Players', permissions: ['superAdmin', 'camp-players'] },*/
  //   ],
  // },
  {
    path: "bookings",
    icon: "fas fa-calendar-week",
    title: "Bookings",
    permissions: [
      "superAdmin",
      "booking.booking-details",
      "booking.booking-summary",
      "booking.booking-income",
    ],
    children: [
      {
        path: "/booking-details",
        icon: "",
        children: [],
        title: "Booking Details",
        permissions: ["superAdmin", "booking.booking-details"],
      },
      {
        path: "/booking-summary",
        icon: "",
        children: [],
        title: "Booking Summary",
        permissions: ["superAdmin", "booking.booking-summary"],
      },
      {
        path: "/booking-income",
        icon: "",
        children: [],
        title: "Booking Income",
        permissions: ["superAdmin", "booking.booking-income"],
      },
    ],
  },
  // {
  //     path: '/premium-accounts',
  //     icon: 'fa fa-users',
  //     title: 'Premium Accounts List',
  //     permissions: ['superAdmin', 'grintafy-plus-list'],
  //     children: []
  // },
  // {
  //   path: "/academy-registeration",
  //   icon: "fa fa-credit-card",
  //   title: "Academy Registeration",
  //   permissions: ["superAdmin", "academy.registeration"],
  //   children: [],
  // },
  // {path: '/g-live', icon: 'fab fa-google', title: 'G-Live', children: []},
  {
    path: "/sponsors",
    icon: "fa fa-credit-card",
    title: "Sponsors",
    permissions: ["superAdmin", "sponsors"],
    children: [],
  },
  {
    path: "/advertisements",
    icon: "fa fa-audio-description",
    title: "Advertisements",
    permissions: ["superAdmin", "advertisements"],
    children: [
      // {
      //     path: '/advertisements',
      //     icon: '',
      //     children: [],
      //     title: 'Popup Ads',
      //     permissions: ['superAdmin', 'player.players']
      // },
      // {path: '/advertisements', icon: '', children: [], title: 'Feed Ads', permissions: ['superAdmin', 'player.players']}
    ],
  },
  // {
  //   path: "/adsStaging",
  //   icon: "fa fa-audio-description",
  //   title: "Ads Staging",
  //   permissions: ["superAdmin", "adsStaging"],
  //   children: [],
  // },
  // {
  //     path: '/featured',
  //     icon: 'fa fa-video',
  //     title: 'Featured Videos',
  //     permissions: ['superAdmin', 'advertisements'],
  //     children: []
  // },
  {
    path: "/promotions",
    icon: "fa fa-gift",
    children: [],
    title: "Promotions",
    permissions: ["superAdmin", "promotions"],
  },
  {
    path: "promocode",
    icon: "fa fa-gift",
    title: "Promo Code",
    permissions: ["superAdmin", "online-promo", "operation-promo"],
    children: [
      {
        path: "/promo-code",
        icon: "",
        children: [],
        title: "Promo Code Marketing",
        permissions: ["superAdmin", "online-promo"],
      },
      {
        path: "/promo-code-operation",
        icon: "",
        children: [],
        title: "Promo Code Operation",
        permissions: ["superAdmin", "operation-promo"],
      },
    ],
  },
  {
    path: "/survey",
    icon: "fa fa-gift",
    children: [],
    title: "Survey",
    permissions: ["superAdmin", "survey"],
  },
  {
    path: '/free-g-plus-subscription',
    icon: 'fa fa-credit-card',
    title: 'Free G+ Subscription',
    permissions: ['superAdmin', 'free-g-plus-subscription'],
    children: []
  },
  {
    path: "/players-details-popup",
    icon: "fa fa-check-circle",
    children: [],
    title: "Players Details Pop-up",
    permissions: ["superAdmin", "players-details-popup"],
  },
  {
    path: "/broadcast",
    icon: "fas fa-broadcast-tower",
    children: [],
    title: "Broadcast",
    permissions: ["superAdmin", "broadcast"],
  },
  // {
  //   path: "/queries",
  //   icon: "fa fa-question",
  //   children: [],
  //   title: "Queries",
  //   permissions: ["superAdmin", "queries"],
  // },
  // {
  //     path: '/subscribers',
  //     icon: 'fas fa-newspaper',
  //     children: [],
  //     title: 'Subscribers',
  //     permissions: ['superAdmin', 'subscribers']
  // },
  // { path: '/grinta-live', icon: 'fa fa-life-ring', title: 'G-live', permissions: ['superAdmin', 'grinta.live'], children: [] },
  // { path: '/career', icon: 'fa fa-graduation-cap', title: 'G-career', permissions: ['superAdmin', 'grinta.career'], children: [] },
  // { path: '/g-advertise', icon: 'fas fa-ad', title: 'G-advertise', permissions: ['superAdmin', 'grinta.advertise'], children: [] },
  {
    path: "/reported-problems",
    icon: "fas fa-exclamation-triangle",
    children: [],
    title: "Reported problems",
    permissions: ["superAdmin", "reported-problems"],
  },
  {
    path: "/sub-admins",
    icon: "fa fa-user-secret",
    title: "Sub Admins",
    permissions: ["superAdmin"],
    children: [],
  },
  // { path: '/scout-users', icon: 'fa fa-user-secret', title: 'Scout Users', permissions: ['superAdmin'], children: [] },
  // { path: '/sa-sub-admins', icon: 'fa fa-user-secret', title: 'South Africa Users', permissions: ['superAdmin'], children: [] },

  {
    path: "settings",
    icon: "fas fa-tools",
    title: "Settings",
    permissions: [
      "superAdmin",
      "settings.countries",
      "settings.states",
      "settings.cities",
      "settings.amenities",
      "settings.field-sizes",
      "settings.match-types",
      "settings.field-types",
      "settings.player-positions",
      "settings.app-version",
      "settings.brands",
      "settings.clubs",
      "settings.currencies",
    ],
    children: [
      {
        path: "/countries",
        icon: "",
        title: "Countries",
        children: [],
        permissions: ["superAdmin", "settings.countries"],
      },
      // {
      //   path: "/states",
      //   icon: "",
      //   title: "States",
      //   children: [],
      //   permissions: ["superAdmin", "settings.states"],
      // },
      {
        path: "/cities",
        icon: "",
        title: "Cities",
        children: [],
        permissions: ["superAdmin", "settings.cities"],
      },
      {
        path: "/amenities",
        icon: "",
        title: "Amenities",
        children: [],
        permissions: ["superAdmin", "settings.amenities"],
      },
      // {
      //   path: "/field-sizes",
      //   icon: "",
      //   title: "Court Size List",
      //   children: [],
      //   permissions: ["superAdmin", "settings.field-sizes"],
      // },
      {
        path: "/match-types",
        icon: "",
        title: "Match Type List",
        children: [],
        permissions: ["superAdmin", "settings.match-types"],
      },
      {
        path: "/field-types",
        icon: "",
        title: "Court Type List",
        children: [],
        permissions: ["superAdmin", "settings.field-types"],
      },
      {
        path: "/player-positions",
        icon: "",
        title: "Player Position List",
        children: [],
        permissions: ["superAdmin", "settings.player-positions"],
      },
      {
        path: "/app-version",
        icon: "",
        title: "Application Versions",
        children: [],
        permissions: ["superAdmin", "settings.app-version"],
      },
      {
        path: "/brands",
        icon: "",
        title: "Brands",
        children: [],
        permissions: ["superAdmin", "settings.brands"],
      },
      {
        path: "/clubs",
        icon: "",
        title: "Clubs",
        children: [],
        permissions: ["superAdmin", "settings.clubs"],
      },
      {
        path: "/currencies",
        icon: "",
        title: "Currencies",
        children: [],
        permissions: ["superAdmin", "settings.currencies"],
      },
    ],
  },
  {
    path: "reports",
    icon: "fa fa-file",
    title: "Reports",
    permissions: [
      "superAdmin",
      "reports.player-reports",
      "reports.ggo-reports",
      "reports.booking-reports",
      "reports.organizers-report",
      "reports.payment-report",
      "reports.registration-reports",
    ],
    children: [
      {
        path: "/player-reports",
        icon: "",
        title: "Player Reports",
        children: [],
        permissions: ["superAdmin", "reports.player-reports"],
      },
      {
        path: "/ggo-reports",
        icon: "",
        title: "PGO Reports",
        children: [],
        permissions: ["superAdmin", "reports.ggo-reports"],
      },
      {
        path: "/ggo-fin-reports",
        icon: "",
        title: "PGO Financial Reports",
        children: [],
        permissions: ["superAdmin", "reports.ggo-fin-reports"],
      },
      {
        path: "/booking-reports",
        icon: "",
        title: "Booking Report",
        children: [],
        permissions: ["superAdmin", "reports.booking-reports"],
      },
      {
        path: "/organizers-report",
        icon: "",
        title: "Organizer Reports",
        children: [],
        permissions: ["superAdmin", "reports.organizers-report"],
      },
      {
        path: "/payment-report",
        icon: "",
        title: "Payment Reports",
        children: [],
        permissions: ["superAdmin", "reports.payment-report"],
      },
      {
        path: "/registration-reports",
        icon: "",
        title: "Registration Reports",
        children: [],
        permissions: ["superAdmin", "reports.registration-reports"],
      },
    ],
  },
  {
    path: "/appdownloads",
    icon: "fa fa fa-download",
    title: "App downloads",
    permissions: ["superAdmin", "app-downloads"],
    children: [],
  },
  // {
  //     path: '/bugsform',
  //     icon: 'fa fa-audio-description',
  //     children: [],
  //     title: 'Bugs Form',
  //     permissions: ['superAdmin', 'advertisements']
  // },

  // {path: '/commission-payment', icon: 'fas fa-money-bill-alt', children: [], title: 'Commission Payment'},
  // {path: '/player-report', icon: '', title: 'Player Reports', children: []},
  // {path: '/payment-report', icon: '', title: 'Payment Reports', children: []},
];

export const sideBarStaff = [
  {
    path: "/dashboard",
    icon: "fa fa-th-large",
    children: [],
    title: "Dashboard",
  },
  {
    path: "bookings",
    icon: "fas fa-calendar-week",
    title: "Bookings",
    children: [
      {
        path: "/booking-details",
        icon: "",
        children: [],
        title: "Booking Details",
      },
      {
        path: "/booking-summary",
        icon: "",
        children: [],
        title: "Booking Summary",
      },
    ],
  },
  {
    path: "owners",
    icon: "fa fa-flag",
    title: "Owner Data",
    children: [
      { path: "/fields", icon: "", children: [], title: "Court List" },
      { path: "/owners", icon: "", children: [], title: "Owner List" },
      { path: "/contracts", icon: "", children: [], title: "Contract List" },
    ],
  },
  {
    path: "reports",
    icon: "fa fa-file",
    title: "Reports",
    children: [
      {
        path: "/player-reports",
        icon: "",
        title: "Player Reports",
        children: [],
      },
      { path: "/ggo-reports", icon: "", title: "GGO Reports", children: [] },
      {
        path: "/booking-reports",
        icon: "",
        title: "Booking Report",
        children: [],
      },
      {
        path: "/organizers-report",
        icon: "",
        title: "Organizer Reports",
        children: [],
      },
      {
        path: "/payment-report",
        icon: "",
        title: "Daily Payment Reports",
        children: [],
      },
    ],
  },
];

export const sideBarBroadcast = [
  {
    path: "/broadcast",
    icon: "fas fa-broadcast-tower",
    children: [],
    title: "Broadcast",
  },
];

export const sideBarEmployee = [
  {
    path: "players",
    icon: "fa fa-users",
    title: "Players data",
    children: [{ path: "/posts", icon: "", children: [], title: "Posts" }],
  },
  {
    path: "/broadcast",
    icon: "fas fa-broadcast-tower",
    children: [],
    title: "Broadcast",
  },
  {
    path: "/sponsors",
    icon: "fa fa-credit-card",
    title: "Sponsors",
    children: [],
  },
  {
    path: "/advertisements",
    icon: "fa fa-audio-description",
    children: [],
    title: "Advertisements",
  },
];

export const GraphColors = [
  {
    // red
    backgroundColor: "transparent",
    borderColor: "red",
    pointBackgroundColor: "rgba(148,159,177,1)",
    pointBorderColor: "#fff",
    pointHoverBackgroundColor: "#fff",
    pointHoverBorderColor: "rgba(148,159,177,0.8)",
  },
  {
    // dark grey
    backgroundColor: "transparent",
    borderColor: "rgba(248,172,89,1)",
    pointBackgroundColor: "rgba(248,172,89,1)",
    pointBorderColor: "#fff",
    pointHoverBackgroundColor: "#fff",
    pointHoverBorderColor: "rgba(248,172,89,1)",
  },
];
export const DonutGraphColors = [
  {
    // red
    backgroundColor: "rgba(255,0,0,0.3)",
    borderColor: "red",
    pointBackgroundColor: "rgba(148,159,177,1)",
    pointBorderColor: "#fff",
    pointHoverBackgroundColor: "#fff",
    pointHoverBorderColor: "rgba(148,159,177,0.8)",
  },
];

export const BarGraphColors = [
  {
    // blue
    backgroundColor: "rgb(245 102 0 / 32%)", //rgba(76,198,200,0.5)
    borderColor: "#d80019",
    pointBackgroundColor: "#d80019",
    pointBorderColor: "#d80019",
    pointHoverBackgroundColor: "#d80019",
    pointHoverBorderColor: "#d80019",
  },
];
