import { Injectable } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

    constructor(private api: ApiService,
        private toastr: ToastrService,
        public router: Router,
        private userService: UserService
        ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Clone the request to add the new header.
        const token = this.userService.getUserToken;
        if (token) {
            req = req.clone({ headers: req.headers.set('authorization', 'bearer ' + token) });
        }

        if (req.reportProgress === true) {
            this.api.loaderOn(true);
        }

        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.api.loaderOn(false);
            }
        },
            (err: any) => {
                this.api.loaderOn(false);
                if (err instanceof HttpErrorResponse) {
                    if (err.error.statusCode === 401) {
                        this.userService.userSignOut();
                        this.router.navigate(['login']);
                    } else {
                        this.toastr.error(err.error.message || 'Something went wrong', 'OOPS!');
                    }
                }
            }));
    }
}
