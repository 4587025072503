export class TableCommonModel {
    totalItems: number;
    search = '';
    title = '';
    allData: any = [];
    currentPage = 1;
    selectedIndex: number;
    owners: any = [];
    fieldTypes: any = [];
    fieldSizes: any = [];
    amenities: any = [];
    nationalities: any = [];
    statuses: any = [];
    states: any = [];
    cities: any = [];
    countries: any = [];
    fieldCount: any;
    selectedField = '';
    id: any;
    showModal = false;
    heading = 'Add';
    myData: any;
    status = '';
    media = [];
    imgURL: any;
    bsValue: any;
    to: any;
    from: any;
    profileData: any;
    msg = 'Added';
    dropdownSettings: any = {};
    selectedOwner = '';
    bsRangeValue: any[];
    dates: any = [];
    startDate: any;
    state: string;
    city: string;
    dob: string;
    yob: any;
    totalClientFields: number;
    totalClientBookings: number;
    totalClientBookingsValue: number;
    forPromoOnly:boolean;
    forPublished:boolean;
}

